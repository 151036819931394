import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChartsService {

  refreshFiltersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  removeFilterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  openFiltersSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  chipsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  selectedTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  toggleMoreFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}